import { Deserializable } from "./deserializable";

export class Pet implements Deserializable {
  id: string;
  name: string;
  gender: string;
  age: string;
  neutered: string;
  vaccinated: string;
  size: string;
  description1: string;
//description2: string;
  image: string;
  cartImage: string;
  petCategory: string;
  price: number;
  adopted: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export const PETS: Pet[] = [

    {
        id: 'pom-puppy-athena', name: 'Athena', image: 'assets/img/pets/athena.jpeg',   cartImage: 'assets/img/cartImage/athena.jpeg',
        gender: 'Female', age: "8 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 800,
        petCategory: "Pomeranian",
        adopted: false,
        description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Glen Pomeranians community.",
    } as Pet,

    {
        id: 'pom-puppy-chico', name: 'Chico', image: 'assets/img/pets/chico.jpeg',   cartImage: 'assets/img/cartImage/chico.jpeg',
        gender: 'Male', age: "10 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 700,
        petCategory: "Pomeranian",
        adopted: false,
        description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Glen Pomeranians community.",
    } as Pet,

    {
        id: 'pom-puppy-daisy', name: 'Daisy', image: 'assets/img/pets/daisy.jpeg',   cartImage: 'assets/img/cartImage/daisy.jpeg',
        gender: 'Female', age: "10 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 800,
        petCategory: "Pomeranian",
        adopted: false,
        description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Glen Pomeranians community.",
    } as Pet,

    {
        id: 'pom-puppy-hercules', name: 'Hercules', image: 'assets/img/pets/hercules.jpeg',   cartImage: 'assets/img/cartImage/hercules.jpeg',
        gender: 'Male', age: "9 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 700,
        petCategory: "Pomeranian",
        adopted: false,
        description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Glen Pomeranians community.",
    } as Pet,

    {
        id: 'pom-puppy-peanut', name: 'Peanut', image: 'assets/img/pets/peanut.jpeg',   cartImage: 'assets/img/cartImage/peanut.jpeg',
        gender: 'Female', age: "10 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 750,
        petCategory: "Pomeranian",
        adopted: false,
        description1: "These are gorgeous energetic Pomeranian puppies. Small, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Glen Pomeranians community.",
    } as Pet,

    {
        id: 'pom-puppy-toby', name: 'Toby', image: 'assets/img/pets/toby.jpeg',   cartImage: 'assets/img/cartImage/toby.jpeg',
        gender: 'Male', age: "9 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 700,
        petCategory: "Pomeranian",
        adopted: false,
        description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Glen Pomeranians community.",
    } as Pet,

    {
        id: 'pom-puppy-willow', name: 'Willow', image: 'assets/img/pets/willow.jpeg',   cartImage: 'assets/img/cartImage/willow.jpeg',
        gender: 'Female', age: "10 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 800,
        petCategory: "Pomeranian",
        adopted: true,
        description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Glen Pomeranians community.",
    } as Pet,

    {
        id: 'pom-puppy-xena', name: 'Xena', image: 'assets/img/pets/xena.jpeg',   cartImage: 'assets/img/cartImage/xena.jpeg',
        gender: 'Female', age: "9 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 700,
        petCategory: "Pomeranian",
        adopted: false,
        description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Glen Pomeranians community.",
    } as Pet,
    // {
    //     id: 'pom-puppy-leo', name: 'Leo', image: 'assets/img/pets/leo.jpg',   cartImage: 'assets/img/cartImage/Leo.jpg',
    //     gender: 'Male', age: "10 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 700,
    //     petCategory: "Pomeranian",
    //     adopted: false,
    //     description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Glen Pomeranians community.",
    // } as Pet,
    // {
    //     id: 'pom-puppy-moana', name: 'Moana', image: 'assets/img/pets/moana.jpg',   cartImage: 'assets/img/cartImage/moana.jpg',
    //     gender: 'Female', age: "11 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 1800,
    //     petCategory: "Pomeranian",
    // adopted: false,
    //     description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Glen Pomeranians community.",
    // } as Pet,

    // {
    //     id: 'pom-puppy-rusty', name: 'Rusty', image: 'assets/img/pets/rusty.jpg',   cartImage: 'assets/img/cartImage/rusty.jpg',
    //     gender: 'Male', age: "11 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 2000,
    //     petCategory: "Pomeranian",
    // adopted: false,
    //     description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Glen Pomeranians community.",
    // } as Pet,

    // {
    //     id: 'pom-puppy-fiona', name: 'Fiona', image: 'assets/img/pets/fiona.jpg',   cartImage: 'assets/img/cartImage/fiona.jpg',
    //     gender: 'Female', age: "11 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 2000,
    //     petCategory: "Pomeranian",
    // adopted: false,
    //     description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Glen Pomeranians community.",
    // } as Pet,
    
    // {
    //     id: 'pom-puppy-fanny', name: 'Fanny', image: 'assets/img/pets/fanny-1.jpg',   cartImage: 'assets/img/cartImage/fanny-1.jpg',
    //     gender: 'Female', age: "11 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 1850,
    //     petCategory: "Pomeranian",
    // adopted: false,
    //     description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Glen Pomeranians community.",
    // } as Pet,
    // {
    //     id: 'pom-puppy-henry', name: 'Henry', image: 'assets/img/pets/henry.jpg',   cartImage: 'assets/img/cartImage/henry.jpg',
    //     gender: 'Male', age: "11 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 2000,
    //     petCategory: "Pomeranian",
    // adopted: false,
    //     description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Glen Pomeranians community.",
    // } as Pet,
    
    // {
    //     id: 'pom-puppy-isabella', name: 'Isabella', image: 'assets/img/pets/isabella.jpg',   cartImage: 'assets/img/cartImage/isabella.jpg',
    //     gender: 'Female', age: "11 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 2000,
    //     petCategory: "Pomeranian",
    // adopted: false,
    //     description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Glen Pomeranians community.",
    // } as Pet,
    // {
    //     id: 'pom-puppy-clyde', name: 'Clyde', image: 'assets/img/pets/clyde.jpg',   cartImage: 'assets/img/cartImage/clyde.jpg',
    //     gender: 'Male', age: "10 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 700,
    //     petCategory: "Pomeranian",
    // adopted: false,
    //     description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Glen Pomeranians community.",
    // } as Pet,
    // {
    //     id: 'pom-puppy-buck', name: 'Buck', image: 'assets/img/pets/buck.jpg',   cartImage: 'assets/img/cartImage/buck.jpg',
    //     gender: 'Male', age: "11 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 2200,
    //     petCategory: "Pomeranian",
    // adopted: false,
    //     description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Glen Pomeranians community.",
    // } as Pet,
];
  